<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
      <b-col cols="12">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: SinglePath.name }"> Single Offers</router-link>
      </b-col>
      <b-col v-if='item'>
        <b-form @submit='onSubmit'>
          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Edit Single Offer ({{ item.name }})</h5>
            </div>
            <div class='card-body'>
              <b-row>
                <b-col lg='6'>
                  <b-form-group label='Status'>
                    <b-form-select id="offer_status" v-model="form.offer_status" :options="offerStatusOptions" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Tier'>
                    <multiselect v-model='form.price_tier' placeholder='Search Tier' label='tier_code'
                      :custom-label="nameWithPrice" track-by='tier_code' open-direction='bottom'
                      :options='priceUsdOptions' :searchable='true' :loading='isPriceUsdSearch' :multiple='false'
                      @search-change='priceUsdFind' @input="tierSelected">
                    </multiselect>
                    <dfn class="text-muted">* set all price by ios tier</dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='4'>
                  <b-form-group label='Web Price USD'>
                    <b-form-input v-model='form.price_usd' type='number' step='any' required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg='4'>
                  <b-form-group label='Web Price IDR'>
                    <b-form-input v-model='form.price_idr' type='number' step='any' required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg='6'>
                  <b-form-group label='IOS Tier'>
                    <multiselect v-model='form.ios_tier' label='tier_code' track-by='tier_code' placeholder='Ios Tier'
                      open-direction='bottom' :options='iosTierSearchOptions' :searchable='true'
                      :loading='isIosTierSearch' :close-on-select='true' :multiple='false' @search-change='iosTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.ios_tier ? form.ios_tier.tier_price : 0 | formatUSD }}
                      <br>
                      IDR : {{ form.ios_tier ? form.ios_tier.price_idr : 0 | formatIDR }}
                    </dfn>
                  </b-form-group>
                </b-col>
                <b-col lg='6'>
                  <b-form-group label='Android Tier'>
                    <multiselect v-model='form.android_tier' label='tier_code' track-by='tier_code'
                      placeholder='Ios Tier' open-direction='bottom' :options='androidTierSearchOptions'
                      :searchable='true' :loading='isAndroidTierSearch' :close-on-select='true' :multiple='false'
                      @search-change='androidTierFind'>
                    </multiselect>
                    <dfn>
                      USD : {{ form.android_tier ? form.android_tier.tier_price : 0 | formatUSD }}
                      <br>
                      IDR : {{ form.android_tier ? form.android_tier.price_idr : 0 | formatIDR }}
                    </dfn>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <b-form-checkbox v-model='form.is_free' :value=true :unchecked-value=false> Free Offer
                    </b-form-checkbox>
                    <b-form-checkbox v-model='allow_diff_price' :value=true :unchecked-value=false
                      v-if="checkbox_diff_price"> Allow Different Price
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant='primary' type='submit'>Update</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SinglePath } from '../../router/marketing';
import constant from '../../store/constant';

export default {
  name: 'editSingleOffer',
  data() {
    return {
      SinglePath,
      debounce: null,
      offer_id: null,
      form: {
        price_usd: null,
        price_idr: null,
        ios_tier: null,
        android_tier: null,
        is_free: false,
        offer_status: null,
      },
      isIosTierSearch: false,
      iosTierSearchOptions: [],
      isAndroidTierSearch: false,
      androidTierSearchOptions: [],
      offerStatusOptions: [
        { value: constant.OFFER_STATUS.READY_FOR_SALE, text: 'On Sale' },
        { value: constant.OFFER_STATUS.NOT_FOR_SALE, text: 'Not Sale' },
      ],
      price_tier: null,
      isPriceUsdSearch: false,
      priceUsdOptions: [],
      allow_diff_price: false,
      checkbox_diff_price: false,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.offers.isError,
      isLoading: (state) => state.offers.isLoading,
      errorMessage: (state) => state.offers.errorMessage,
      successMessage: (state) => state.offers.successMessage,
      item: (state) => state.offers.item,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.fetchOffersById(this.offer_id);
    },
    item: function () {
      if (!this.item) {
        this.messageAlert('error', 'not found');
        return;
      }
      if (!Object.keys(this.item).length) return;
      this.setEditOffers();
    },
  },
  created() {
    this.offer_id = this.$route.params.id;
    this.fetchOffersById(this.offer_id);
    this.priceUsdFind('c.usd.');
  },
  methods: {
    ...mapActions('offers', ['updateSingleOffers', 'fetchOffersById']),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTier',
      fetchAndroidTierById: 'fetchAndroidTierById',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: 'searchIosTier',
      fetchIosTierById: 'fetchIosTierById',
    }),

    onSubmit(event) {
      event.preventDefault();
      this.checkbox_diff_price = false;
      const data = this.form;
      if (!data.android_tier?.id) {
        this.messageAlert('error', 'android tier not selected!', 5000);
        return;
      }
      if (!data.ios_tier?.id) {
        this.messageAlert('error', 'IOS tier not selected!', 5000);
        return;
      }
      if (!this.checkDiffPrice()) {
        this.messageAlert('warning', 'found different price. please allow checkbox to continue!', 5000);
        this.checkbox_diff_price = true;
        if (!this.allow_diff_price) return
      }

      const payload = {
        offer_id: this.offer_id,
        offer_type_id: constant.OFFER_TYPE.SINGLE,
        android_tier_id: data.android_tier.id,
        ios_tier_id: data.ios_tier.id,
        offer_status: data.offer_status,
        price_usd: data.price_usd,
        price_idr: data.price_idr,
        is_free: data.is_free,
      };
      this.updateSingleOffers(payload);
    },

    checkDiffPrice() {
      const web = parseFloat(this.form.price_idr);
      const ios = parseFloat(this.form.ios_tier?.price_idr);
      const android = parseFloat(this.form.android_tier?.price_idr);
      const priceCheck = new Set([web, ios, android]);
      return (priceCheck.size == 1);
    },

    priceUsdFind(query) {
      if (!query) return;

      this.isPriceUsdSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          const rows = (response.data?.data?.rows ?? []);
          this.priceUsdOptions = rows;
          this.isPriceUsdSearch = false;
        }).catch(() => {
          this.isPriceUsdSearch = false;
        });
      }, 600);
    },

    nameWithPrice({ tier_price, tier_code }) {
      return `${tier_price} — [${tier_code}]`;
    },

    async tierSelected(data) {
      if (!data) return;
      this.searchIosTier({ q: data?.tier_code }).then((response) => {
        this.iosTierSearchOptions = response.data.data?.rows;
        this.form.ios_tier = response.data.data?.rows[0] ?? null;
      });
      this.searchAndroidTier({ q: data?.tier_code }).then((response) => {
        this.androidTierSearchOptions = response.data.data.rows;
        this.form.android_tier = response.data.data?.rows[0] ?? null;
      });
      this.form.price_usd = parseFloat(data?.tier_price);
      this.form.price_idr = parseFloat(data?.price_idr);
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = response.data.data.rows;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = response.data.data.rows;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    setEditOffers() {
      const data = this.item;
      this.form.price_usd = data.price_usd;
      this.form.price_idr = data.price_idr;
      this.form.is_free = data.is_free;
      this.form.offer_status = data.offer_status;

      const androidTier = (data.platform_offers ?? []).find(val => val.platform_id == constant.PLATFORM.ANDROID);
      const iosTier = (data.platform_offers ?? []).find(val => val.platform_id == constant.PLATFORM.IOS);

      this.fetchAndroidTierById(androidTier.tier_id).then(response => {
        this.form.android_tier = response;
        this.androidTierSearchOptions = [response];
      });
      this.fetchIosTierById(iosTier.tier_id).then(response => {
        this.form.ios_tier = response;
        this.iosTierSearchOptions = [response];
      });

    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
};
</script>
